import React from 'react';
import HeroImage from '../../../images/icons/hero-image';
import styles from './styles.module.scss';
import { useStaticQuery, graphql } from 'gatsby';

const Hero = () => {
    const {
        HomeHero: {
            frontmatter: { mainHeading, subHeading },
        },
    } = useStaticQuery(graphql`
        query HomeHero {
            HomeHero: markdownRemark(fileAbsolutePath: { regex: "/home-hero/" }) {
                frontmatter {
                    mainHeading: home_hero_main_heading
                    subHeading: home_hero_sub_heading
                }
            }
        }
    `);
    return (
        <div
            className={`bg-primary ${styles.hero} flex flex-col justify-center lg:h-screen text-primary `}
        >
            <div className=" max-w-4xl px-5 container  mx-auto  flex flex-col justify-between items-center">
                <h1 className="m-0 text-center">{mainHeading}</h1>
                <div className={styles.background}>
                    <HeroImage />
                </div>
                <h2 className="m-0  text-center leading-tight">{subHeading}</h2>
            </div>
        </div>
    );
};

export default Hero;
