import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import Img from 'gatsby-image';
import styles from './styles.module.scss';
import Button from '@components/Button';
import { format, parse } from 'date-fns';

const Blogs = () => {
    const {
        blogs: { edges: blogs },
    } = useStaticQuery(graphql`
        query Blogs {
            blogs: allMarkdownRemark(
                filter: { fileAbsolutePath: { regex: "/blogs/(?!.md$)/" } }
                limit: 2
            ) {
                edges {
                    node {
                        frontmatter {
                            heading: blogs_blog_title
                            image: blogs_blog_overview_image {
                                childImageSharp {
                                    fluid(maxWidth: 1000, maxHeight: 700) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                            description: blogs_blog_overview_description
                            date: blogs_blog_date
                            path
                        }
                    }
                }
            }
        }
    `);

    return (
        <div className={`${styles.blog} pt-12 pb-24`}>
            <div className="max-w-6xl px-5 xl:px-0 mx-auto">
                <div className="flex justify-between flex-wrap px-5">
                    <h2>Our blog posts</h2>
                    <Link to="/blog" className="primary">
                        <Button text="View all blogs" />
                    </Link>
                </div>
                <div className="mt-8 -mb-8 flex flex-wrap">
                    {blogs?.map(
                        ({
                            node: {
                                frontmatter: { heading, description, image, date, path },
                            },
                        }) => {
                            return (
                                <div
                                    key={path}
                                    className={`w-full lg:w-1/2 mb-8 flex justify-center `}
                                    style={{ minHeight: '200px' }}
                                >
                                    <Link
                                        className={`w-full lg:w-11/12 flex items-center  flex-wrap ${styles.blogCard} `}
                                        to={path}
                                    >
                                        <div className="w-full lg:w-2/5 lg:h-full">
                                            <Img
                                                style={{ height: '100%' }}
                                                fluid={image?.childImageSharp?.fluid}
                                                imgStyle={{ objectFit: 'cover' }}
                                            />
                                        </div>
                                        <div
                                            className={`w-full lg:w-3/5 lg:h-full  px-6 py-12 ${styles.blogOverviewCard}`}
                                        >
                                            <h4 className="m-0">{heading}</h4>
                                            <p className="font-medium font-lg m-0 mt-2 text-black">
                                                {format(parse(date, 'dd/MM/yyyy', new Date()), 'do MMMM yyyy')}
                                            </p>
                                            <p
                                                className={`${styles.blogOverviewDescription} m-0 mt-2`}
                                            >
                                                {description}
                                            </p>
                                        </div>
                                    </Link>
                                </div>
                            );
                        }
                    )}
                </div>
            </div>
        </div>
    );
};

export default Blogs;
