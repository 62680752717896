import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { Divider, Row, Col, Grid } from '@components/Grid';
import Img from 'gatsby-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from './styles.module.scss';
import ShowcaseCard from '@components/ShowcaseCard';
import Button from '@components/Button';

const RecentCaseStudies = () => {
    let {
        services: { edges: services },
        caseStudies: { edges: caseStudies },
    } = useStaticQuery(graphql`
        query RecentCaseStudies {
            services: allMarkdownRemark(
                filter: { fileAbsolutePath: { regex: "/services/(?!.md$)/" } }
            ) {
                edges {
                    node {
                        frontmatter {
                            path
                            code: services_service_code
                            name: services_service_heading
                        }
                    }
                }
            }
            caseStudies: allMarkdownRemark(
                filter: { fileAbsolutePath: { regex: "/case-studies/(?!.md$)/" } }
                limit: 3
            ) {
                edges {
                    node {
                        frontmatter {
                            path
                            heading: portfolio_item_title
                            description: portfolio_item_description
                            image: portfolio_item_desktop_image {
                                childImageSharp {
                                    fluid(maxWidth: 1280, maxHeight: 720) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                            favourite: portfolio_item_favourite
                            displayedServices: portfolio_item_displayed_services {
                                path: portfolio_item_displayed_services_link
                                name: portfolio_item_displayed_services_name
                            }
                        }
                    }
                }
            }
        }
    `);

    // map real services into case study data
    caseStudies = caseStudies?.map(({ node: { frontmatter: data } }) => ({
        ...data,
        services: services
            ?.filter(({ node: { frontmatter: { code } } }) =>
                data?.services?.some((portfolioService) => portfolioService === code)
            )
            ?.map((service) => service?.node?.frontmatter),
    }));

    return (
        <div className={`${styles.recentProjects} pt-12 pb-12`}>
            <div className="max-w-6xl px-5 xl:px-0 mx-auto">
                <div className="flex justify-between flex-wrap px-3">
                    <h2>Our recent case studies</h2>
                    <Link to="/portfolio" className="primary">
                        <Button text="View all case studies" />
                    </Link>
                </div>

                <Divider margin={5} />

                <div className="mt-8 -mb-8 flex flex-wrap">
                    {caseStudies?.map((item, index) => {
                        return (
                            <div
                                key={`${item?.path}_${index}`}
                                className={`w-full lg:w-1/2 lg:w-1/3 flex justify-center mb-8 ${styles.showcaseParent}`}
                            >
                                <ShowcaseCard {...item} />
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default RecentCaseStudies;
