import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import ServiceCard from '@components/ServiceCard';
import styles from '@pages/styles.module.scss';

function Services() {
    const {
        Services: { edges: services },
    } = useStaticQuery(graphql`
        query Services {
            Services: allMarkdownRemark(
                filter: { fileAbsolutePath: { regex: "/services/(?!.md$)/" } }
                limit: 6
            ) {
                edges {
                    node {
                        frontmatter {
                            heading: services_service_heading
                            image: services_service_image {
                                childImageSharp {
                                    fluid(maxWidth: 800) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                            description: services_service_description
                            path
                        }
                    }
                }
            }
        }
    `);

    return (
        <div className={styles.services}>
            <div className="max-w-6xl mx-auto container px-5 mt-12">
                <h2>Services we provide</h2>
            </div>
            <div className="max-w-6xl mx-auto py-2 lg:py-6 container px-5">
                <div className={`flex flex-wrap -mr-6 xl:-mr-12`}>
                    {services.map(({ node: { frontmatter: data } }, index) => (
                        <div
                            key={index}
                            className={`${styles.serviceCardWrapper} w-full md:w-1/2 lg:w-1/3 mb-8`}
                        >
                            <div className="pr-6 xl:pr-8 h-full">
                                <ServiceCard data={data} />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Services;
