import React from 'react';
import { Col, Divider, Grid, Row } from '@components/Grid';
import Button from '@components/Button';
import styles from './styles.module.scss';
import { Link } from 'gatsby';

const Description = () => {
    return (
        <section>
            <Grid>
                <Row>
                    <Col xs={12}>
                        <div className={styles.descriptionContainer}>
                            <h2>Why are we better?</h2>
                            <p>
                                At Element Softworks, we build everything from our bespoke library of
                                components. It’s clean, precise and quick. Our methodology is finely
                                tuned to the modern web. We practice web-design & development to
                                have a foreseeable lifetime and to withstand the aggressively
                                progressing web. We are not held down by the bloat & constraints of
                                Wordpress, Shopify or any web-site builder, all of our offerings are
                                bespoke.
                            </p>
                            <p>Read more about us and our technologies:</p>
                            <Link to="/about">
                                <Button text="Who are we?" />
                            </Link>
                        </div>
                    </Col>
                </Row>
            </Grid>
        </section>
    );
};

export default Description;
