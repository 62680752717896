import React from 'react';
import Img from 'gatsby-image';
import { Link } from 'gatsby';

import styles from './styles.module.scss';

function ShowcaseCard({
    favourite,
    heading,
    description,
    image,
    displayedServices,
    path,
}) {
    return (
        <div className={`bg-white w-full h-full lg:w-11/12 ${styles.ShowcaseCard}`}>
            <Link to={path}>
                <Img fluid={image?.childImageSharp.fluid} />
                <div className="p-6">
                    <h4>{heading}</h4>
                    <p>{description}</p>
                    <div className="mt-4 flex flex-wrap">
                        {!!favourite && (
                            <span
                                className={`mr-2 mb-2 cursor-pointer text-xs ${styles.tag} ${styles.fav}`}
                            >
                                Favourite
                            </span>
                        )}

                        {displayedServices?.map(({ name, path }) => {
                            return (
                                <Link
                                    to={path}
                                    key={path}
                                    className={`mr-2 cursor-pointer mb-2 text-xs ${styles.tag} ${
                                        name.toLowerCase() === 'favourite' && `${styles.fav}`
                                    }`}
                                >
                                    <span>{name}</span>
                                </Link>
                            );
                        })}
                    </div>
                </div>
            </Link>
        </div>
    );
}

export default ShowcaseCard;
