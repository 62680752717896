import React from 'react';
import { Col, Divider, Grid, Row } from '@components/Grid';
import Layout from '@components/Layout';
import Hero from '@views/home/Hero';
import Services from '@views/home/Services';
import Brochure from '@views/home/brochure';
import Showcase from '@views/home/showcase';
import Blogs from '@views/home/blogs';
import Testimonials from '@views/home/Testimonials';

import styles from './styles.module.scss';
import StartProjectCta from '@components/StartProjectCta';
import Description from '@views/home/Description';
import RecentCaseStudies from '@components/RecentCaseStudies';

const Home = () => {
    return (
        <Layout>
            <Hero />
            <div className="overflow-x-hidden" style={{ position: 'relative' }}>
                <Divider xsMargin={5} lgMargin={3} xlMargin={1} />
                <Description />

                <Divider margin={5} />
                <Divider xsMargin={0} smMargin={5} />

                <Services />
                <Showcase />
                <Brochure />
                <Testimonials />
                <StartProjectCta />
                <RecentCaseStudies />
                <hr />
                <Blogs />

                <span className={`${styles.shapesShape} ${styles.shapesOne}`} />
                <span className={`${styles.shapesShape} ${styles.shapesTwo}`} />
                <span className={`${styles.shapesShape} ${styles.shapesThree}`} />
                <span className={`${styles.shapesShape} ${styles.shapesFour}`} />
                <span className={`${styles.shapesShape} ${styles.shapesFive}`} />
                <span className={`${styles.shapesShape} ${styles.shapesSix}`} />
                <span className={`${styles.shapesShape} ${styles.shapesSeven}`} />
                <span className={`${styles.shapesShape} ${styles.shapesEight}`} />
                <span className={`${styles.shapesShape} ${styles.shapesNine}`} />
                <span className={`${styles.shapesShape} ${styles.shapesTen}`} />
                <span className={`${styles.shapesShape} ${styles.shapesEleven}`} />
                <span className={`${styles.shapesShape} ${styles.shapesTwelve}`} />
                <span className={`${styles.shapesShape} ${styles.shapesThirteen}`} />
            </div>
        </Layout>
    );
};

export default Home;
