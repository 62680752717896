import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import Button from '@components/Button';
function Brochure() {
    const {
        Brochure: {
            frontmatter: { title, images, description, buttonText, buttonLink },
        },
    } = useStaticQuery(graphql`
        query Brochure {
            Brochure: markdownRemark(fileAbsolutePath: { regex: "/brochure/" }) {
                frontmatter {
                    title: home_brochure_title
                    images: home_brochure_images {
                        childImageSharp {
                            fluid(maxWidth: 600) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    description: home_brochure_description
                    buttonText: home_brochure_button_text
                    buttonLink: home_brochure_button_link
                }
            }
        }
    `);
    return (
        <div className="bg-primary">
            <div className="max-w-6xl flex container flex-wrap mx-auto py-24">
                <div className="w-full lg:w-1/2 p-8 h-full relative">
                    {/* {images?.map(image => { */}
                    {/* return  */}
                    <Img fluid={images[0]?.childImageSharp.fluid} />
                    {/* })} */}
                </div>
                <div className="w-full lg:w-1/2 px-5 py-8 lg:p-16">
                    <h2 className="text-black text-5xl">{title}</h2>
                    <p className="mt-8 text-black font-medium text-2xl">{description}</p>
                    <a rel="noreferrer noopener" href={buttonLink}>
                        <Button inverse text={buttonText} />
                    </a>
                </div>
            </div>
        </div>
    );
}

export default Brochure;
