import React from 'react';
import Img from 'gatsby-image';
import { Link } from 'gatsby';
import styles from './styles.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ServiceCard = ({ arrow, data: { heading, description, image, path } }) => {
    return (
        <div className={styles.serviceCard}>
            <Link to={path}>
                <Img fluid={image?.childImageSharp.fluid} />
                <div className={styles.serviceCardContent}>
                    <h4>{heading}</h4>
                    {!!arrow ? (
                        <div>
                            {!!description && (
                                <div dangerouslySetInnerHTML={{ __html: description }} />
                            )}
                            {!!arrow && <FontAwesomeIcon icon={['far', 'long-arrow-right']} />}
                        </div>
                    ) : (
                        <p>{description}</p>
                    )}
                </div>
            </Link>
        </div>
    );
};

export default ServiceCard;
