import React, { useEffect, useState } from 'react';
import styles from '@pages/styles.module.scss';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MainContext } from '@helpers/contexts';

const Showcases = () => {
    const $showcases = { primary: {}, secondary: {}, text: {} };

    const showcases = useStaticQuery(graphql`
        query Showcase {
            showcase: allMarkdownRemark(
                filter: {
                    fileAbsolutePath: { regex: "/case-studies/" }
                    frontmatter: { portfolio_item_favourite: { eq: true } }
                }
            ) {
                edges {
                    node {
                        frontmatter {
                            path
                            heading: portfolio_item_title
                            subHeading: portfolio_item_sub_heading
                            desktopImage: portfolio_item_desktop_image {
                                childImageSharp {
                                    fluid(maxWidth: 900) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                            mobileImage: portfolio_item_mobile_image {
                                childImageSharp {
                                    fluid(maxWidth: 300) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                            priority: portfolio_item_priority
                        }
                    }
                }
            }
        }
    `)
        ?.showcase?.edges?.map(showcase => showcase.node.frontmatter)
        ?.sort((a, b) => (!a?.priority || !b?.priority ? 1 : a?.priority > b?.priority ? 1 : -1));

    return (
        <MainContext.Consumer>
            {$main => (
                <div className={`${styles.showcases} py-12 px-5 xl:px-0`}>
                    <div className="max-w-6xl mx-auto xl:px-5">
                        <h2>Showcase</h2>
                    </div>
                    <div className="w-full -mt-8 py-2 lg:py-0">
                        {showcases?.map((showcase, index) => (
                            <Showcase
                                key={JSON.stringify(showcase)}
                                $showcases={$showcases}
                                showcase={showcase}
                                index={index}
                                $main={$main}
                            />
                        ))}
                    </div>
                </div>
            )}
        </MainContext.Consumer>
    );
};

const Showcase = ({
    $main,
    $showcases,
    index,
    showcase: { heading, subHeading, desktopImage, mobileImage, path },
}) => {
    const [offsets, setOffsets] = useState({ primary: null, secondary: null, text: null });
    const flip = index % 2 === 0;

    useEffect(() => {
        $main?.current?.addEventListener('scroll', handleScroll);
        handleScroll();
        return () => $main?.current?.removeEventListener('scroll', handleScroll);
    }, []);

    const handleScroll = () => {
        setOffsets({
            primary: $showcases.primary[index]?.getBoundingClientRect().top / 15,
            secondary: $showcases.secondary[index]?.getBoundingClientRect().top / 25,
            text: $showcases.text[index]?.getBoundingClientRect().top / 30,
        });
    };

    return (
        <div
            key={path}
            className={`${styles.showcase} flex flex-wrap w-full lg:mb-56 py-8 lg:py-12 ${
                flip ? 'flex-wrap justify-start' : 'flex-wrap-reverse justify-end'
            }`}
            style={{ minHeight: `${600 + offsets.primary}px` }}
        >
            <div
                className={`${styles.showcaseImages} w-full lg:w-1/2 lg:relative ${
                    flip ? 'order-first -ml-5 xl:-ml-0' : 'order-last -mr-5 xl:mr-0'
                }`}
            >
                <div
                    ref={instance => ($showcases.primary[index] = instance)}
                    style={{
                        top: offsets.primary,
                    }}
                    className={`${styles.desktopImage} lg:relative ${flip ? 'mr-auto' : 'ml-auto'}`}
                >
                    <Img
                        imgStyle={{
                            objectPosition: `${flip ? 'right' : 'left'} top`,
                        }}
                        fluid={desktopImage?.childImageSharp?.fluid}
                    />
                </div>
                <div
                    ref={instance => ($showcases.secondary[index] = instance)}
                    className={`lg:absolute hidden lg:block bottom-0 ${styles.mobileImage} ${
                        styles.even
                    } ${flip ? `${styles.even} right-0` : `${styles.odd} left-0`}`}
                    style={{
                        bottom: offsets.secondary,
                    }}
                >
                    <Img
                        imgStyle={{ objectPosition: 'top center' }}
                        fluid={mobileImage?.childImageSharp?.fluid}
                    />
                </div>
            </div>
            <div
                ref={instance => ($showcases.text[index] = instance)}
                className={`${styles.showcaseText} w-full lg:w-1/2 text-black ${
                    flip ? 'flex justify-start' : 'flex justify-end'
                }`}
                style={{ top: offsets.text }}
            >
                <Link to={path}>
                    <h2 className="mb-0">{heading}</h2>
                    <p className="mt-6 font-medium text-black text-2xl">{subHeading}</p>

                    <span className={`${styles.showcaseTextIcon} mt-8 cursor-pointer`}>
                        <FontAwesomeIcon icon="long-arrow-right" size="3x" />
                    </span>
                </Link>
            </div>
        </div>
    );
};

export default Showcases;
