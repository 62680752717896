import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const TestimonialCard = ({
    data : {
        node : {
            frontmatter : {
                heading,description,subHeading,review,rating
            }
        }
    }
})=>{
    return(
<div className="bg-white p-8 flex flex-col h-full justify-between">
<div className="">
    <h4 className="my-1">{heading}</h4>
    <span className="italic font-medium">{subHeading}</span>
    <p className="mt-4 font-medium">{review}</p>
    </div>
    <span className="mt-8">
        {Array.from({length : parseInt(rating)},(rating, index)=>{
            return(
                <FontAwesomeIcon key={index} icon="star" color="#FFD000"/>
            )
        })}
    </span>


</div>
    )
}

export default TestimonialCard
