import React, { useMemo } from 'react';
import Swiper from 'react-id-swiper';
import { useStaticQuery, graphql } from 'gatsby';
import TestimonialCard from '@components/TestimonialCard';
import styles from './styles.module.scss';
import _ from 'lodash';
const Testimonials = () => {
    const {
        Testimonials: { edges },
        Clients: {
            frontmatter: { images },
        },
    } = useStaticQuery(graphql`
        query Testimonials {
            Testimonials: allMarkdownRemark(
                filter: { fileAbsolutePath: { regex: "/testimonials/" } }
            ) {
                edges {
                    node {
                        frontmatter {
                            heading: reviews_review_name
                            subHeading: reviews_review_projects
                            review: reviews_review_review
                            rating: reviews_review_rating
                        }
                    }
                }
            }
            Clients: markdownRemark(fileAbsolutePath: { regex: "/client-logos/" }) {
                frontmatter {
                    images: client_logos {
                        publicURL
                    }
                }
            }
        }
    `);

    const replicateArray = (array, n) => {
        let arrays = Array.apply(null, new Array(n));

        arrays = arrays.map(function() {
            return array;
        });

        return [].concat.apply([], arrays);
    };

    const splittedLogos = useMemo(() => _.chunk(images, parseInt(images?.length / 2)), []);
    const firstSlider = useMemo(() => replicateArray(splittedLogos[0], 2), []);
    const secondSlider = useMemo(() => replicateArray(splittedLogos[1], 2), []);

    const params = {
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true,
        },
        dots: true,
        spaceBetween: 30,
        slidesPerView: 3,
        breakpoints: {
            1400: {
                slidesPerView: 3,
                spaceBetween: 30,
            },
            1020: {
                slidesPerView: 3,
                spaceBetween: 30,
            },
            // when window width is >= 640px
            640: {
                slidesPerView: 2,
                spaceBetween: 30,
            },
            // when window width is >= 480px
            480: {
                slidesPerView: 1.5,
                spaceBetween: 30,
            },
            // when window width is >= 320px
            320: {
                slidesPerView: 1.25,
                spaceBetween: 20,
            },
        },
    };

    return (
        <div className={`${styles.testimonialsMain} pb-12 pt-20 overflow-hidden`}>
            <div className="max-w-6xl mx-auto px-5 xl:px-0">
                <h2>Reviews and Clients</h2>

                <div className="mt-12 w-full flex overflow-visible">
                    <Swiper {...params}>
                        {edges?.map((edge, index) => (
                            <div key={index}>
                                <TestimonialCard data={edge} />
                            </div>
                        ))}
                    </Swiper>
                </div>
            </div>
            <div className="mt-32 ">
                <div className={`${styles.slider} ${styles.firstSlider} flex my-12`}>
                    {firstSlider?.map(({ publicURL }, index) => {
                        return (
                            <img
                                alt="Logo"
                                key={`${publicURL}_${index}`}
                                className={`${styles.logo}`}
                                src={publicURL}
                            />
                        );
                    })}
                </div>
                <div className={`${styles.slider} ${styles.secondSlider} flex my-12`}>
                    {secondSlider?.map(({ publicURL }, index) => {
                        return (
                            <img
                                alt="Logo"
                                key={`${publicURL}_${index}`}
                                className={`${styles.logo}`}
                                src={publicURL}
                            />
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default Testimonials;
